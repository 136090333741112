import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import { keyframes } from '@mui/system';

import { login } from '../../../core/api/auth';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = () => {
  const token = localStorage.getItem('authToken');

  try {
    const decoded = jwtDecode(token);
    const isExpired = Date.now() >= decoded.exp * 1000;

    if (!isExpired) {
      return <Navigate to="/dashboard" replace />;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const flameFlicker = keyframes`
  0% {
    box-shadow: 0 0 5px #FF9800, 0 0 10px #FF9800, 0 0 20px #FF9800;
  }
  25% {
    box-shadow: 0 0 10px #FFA726, 0 0 15px #FB8C00, 0 0 25px #FFE0B2;
  }
  50% {
    box-shadow: 0 0 5px #FB8C00, 0 0 10px #FFA726, 0 0 20px #FFAB40;
  }
  75% {
    box-shadow: 0 0 10px #FB8C00, 0 0 20px #FFA726, 0 0 35px #FFE0B2;
  }
  100% {
    box-shadow: 0 0 5px #FF9800, 0 0 10px #FF9800, 0 0 20px #FF9800;
  }
`;

const mainContainerSx = {
  p: 2,
  backgroundColor: '#161d27',
  color: '#ccc',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const loginBoxSx = {
  backgroundColor: '#161d27',
  border: '1px solid #444',
  color: '#ccc',
  p: 2,
  borderRadius: '5px',
  width: '360px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  animation: `${flameFlicker} 4s infinite ease-in-out`,
};

const textFieldSx = {
  mb: 2,
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#444',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#444',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#444',
    },
  },
  '& .MuiInputLabel-root': { color: '#ccc' },
  input: { color: '#ccc' },
};

const buttonSx = {
  height: 50,
  fontSize: '1rem',
  textTransform: 'none',
  border: '1px solid #444',
  backgroundColor: '#161d27',
  color: '#ccc',
  mb: 1.5,
  '&:hover': {
    backgroundColor: '#222a35',
  },
};

const LoginPage = () => {
  const [showLoginButtons, setShowLoginButtons] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (e) => e.preventDefault();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
      const { token } = response;
      localStorage.setItem('authToken', token);
      window.location.href = '/dashboard';
    } catch (error) {
      setMessage(error.message);
    }
  };

  const resetLoginForm = () => {
    setMessage('');
    setEmail('');
    setPassword('');
    setShowPassword(false);
  };

  const handleNextFromLoginButtons = () => {
    setShowLoginButtons(false);
    setShowLogin(true);
  };

  const handleBackToFrame = () => {
    setShowLoginButtons(true);
    resetLoginForm();
  };

  const handleBackToWaitlist = () => {
    setShowLogin(false);
    setShowLoginButtons(true);
    resetLoginForm();
  };

  // The first view: Social logins, create account, or sign in
  const loginButtons = (
    <>
      {message && (
        <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
          {message}
        </Alert>
      )}

      <Typography sx={{ color: '#2e88bf', fontSize: '1.25rem', fontWeight: 'bold', mb: 2 }}>
        Continue with
      </Typography>

      <Button startIcon={<GoogleIcon sx={{ fontSize: 30 }} />} sx={buttonSx} fullWidth>
        Google
      </Button>

      <Button startIcon={<AppleIcon sx={{ fontSize: 30 }} />} sx={buttonSx} fullWidth>
        Apple
      </Button>

      <Typography sx={{ my: 2, color: '#ccc', fontWeight: 600 }}>or</Typography>

      <Button sx={buttonSx} fullWidth>
        Create account
      </Button>

      <Typography sx={{ fontSize: '0.9rem', color: '#555', mt: 2, mb: 2 }}>
        By signing up, you agree to the Terms of Service and Privacy Policy, including Cookie Use.
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Typography sx={{ color: '#ccc' }}>Already have an account?</Typography>
        <Button
          sx={{ color: '#2e88bf', textTransform: 'none', p: 0, minWidth: 'auto' }}
          onClick={handleNextFromLoginButtons}
        >
          Sign in
        </Button>
      </Box>

      <Button onClick={handleBackToFrame} sx={{ mt: 2, color: '#2e88bf', textTransform: 'none' }}>
        Back
      </Button>
    </>
  );

  // The second view: Actual email + password sign in
  const renderLogin = (
    <>
      <Typography variant="h6" sx={{ mb: 2, color: '#ccc', fontWeight: 'bold' }}>
        Sign in
      </Typography>
      {message && (
        <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
          {message}
        </Alert>
      )}
      <Box component="form" onSubmit={handleLogin} sx={{ width: '100%', mb: 2 }}>
        <TextField
          variant="outlined"
          label="Email Address"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={textFieldSx}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon sx={{ color: '#ccc' }} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={textFieldSx}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon sx={{ color: '#ccc' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility sx={{ color: '#ccc' }} />
                  ) : (
                    <VisibilityOff sx={{ color: '#ccc' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit"
          sx={{
            ...buttonSx,
            mt: 2,
          }}
          fullWidth
        >
          Login
        </Button>
      </Box>

      <Button onClick={handleBackToWaitlist} sx={{ mt: 2, color: '#2e88bf', textTransform: 'none' }}>
        Back
      </Button>
    </>
  );

  const contentToRender = showLoginButtons ? loginButtons : showLogin ? renderLogin : null;

  return (
    <Box sx={mainContainerSx}>
      <ProtectedRoute />
      <Box sx={loginBoxSx}>{contentToRender}</Box>
    </Box>
  );
};

export default LoginPage;
