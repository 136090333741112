import React, { useState, useEffect } from 'react';
import {
  Box, Card, CardContent, Typography, Button,
  Select, MenuItem, FormControl, InputLabel, Alert, CircularProgress,
  Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Grid, Avatar
} from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import { getAgentDetails, getAgentConfigExist, getUserSourceType } from '../../../../core/api/auth';

const modelList = [
  { model_id: 1, model_name: 'NG-Default' },
  { model_id: 10, model_name: 'NG-Obi' },
  { model_id: 11, model_name: 'NG-Tunde' },
  { model_id: 12, model_name: 'NG-Amara' },
  { model_id: 13, model_name: 'NG-Joy' },
  { model_id: 14, model_name: 'NG-Sam' },
  { model_id: 15, model_name: 'NG-Nne-Good' },
  { model_id: 16, model_name: 'NG-Ifeanyi' },
  { model_id: 17, model_name: 'NG-Chiamaka' },
  { model_id: 18, model_name: 'NG-Femi' },
  { model_id: 19, model_name: 'NG-Eze' },
  { model_id: 20, model_name: 'NG-Tayo' },
  { model_id: 21, model_name: 'NG-Ulunna' },
  { model_id: 22, model_name: 'NG-Ajibo' },
  { model_id: 23, model_name: 'NG-Ben' },
  { model_id: 24, model_name: 'NG-Chika-Obi' },
  { model_id: 25, model_name: 'NG-Amaka' },
  { model_id: 26, model_name: 'NG-Eve' },
  { model_id: 27, model_name: 'NG-Zuba' },
  { model_id: 28, model_name: 'NG-Cartel' },
  { model_id: 29, model_name: 'NG-Werey' },
  { model_id: 9, model_name: 'NG-Olodo' }
];

const socialBaseUrls = {
  twitter: 'https://twitter.com/',
  facebook: 'https://facebook.com/',
  instagram: 'https://instagram.com/',
  tiktok: 'https://tiktok.com/@'
};

const descriptionOptions = ["Empathetic", "Innovative", "Reliable", "Dynamic", "Passionate", "Creative"];
const behavioralOptions = ["Calm", "Assertive", "Flexible", "Persistent", "Adaptable", "Thoughtful"];

const getSelectedAgentDetails = async (id) => {
  try {
    const resp = await getAgentDetails(id);
    const details = resp.result;
    return details ? details.properties : null;
  } catch (err) {
    console.error('Error fetching Agent details:', err);
    return null;
  }
};

const checkIfPostConfigExistsAndSave = async (agentId, source, secondary, activePeriod, configPayload) => {
  try {
    const resp = await getAgentConfigExist(55, agentId, source, secondary, activePeriod, configPayload);
    if (!resp.exist) {
      return true;
    }
    return false;
  } catch (err) {
    console.error('Error checking config existence:', err);
    return false;
  }
};

const SocialAccounts = () => {
  // existing states
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [agentProps, setAgentProps] = useState(null);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [loadingSource, setLoadingSource] = useState(false);
  const [selectedSource, setSelectedSource] = useState('');
  const [currentSecondaries, setCurrentSecondaries] = useState([]);
  const [selectedSecondary, setSelectedSecondary] = useState('');
  const [activePeriod, setActivePeriod] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [socialLinks, setSocialLinks] = useState({
    twitter: '',
    facebook: '',
    instagram: '',
    tiktok: ''
  });
  const [addLater, setAddLater] = useState(false);

  // state for avatar
  const [selectedAvatar, setSelectedAvatar] = useState('');

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [availableDescriptions, setAvailableDescriptions] = useState([]);
  const [selectedDescriptions, setSelectedDescriptions] = useState([]);
  const [availableBehaviorals, setAvailableBehaviorals] = useState([]);
  const [selectedBehaviorals, setSelectedBehaviorals] = useState([]);
  const [customDescriptions, setCustomDescriptions] = useState([]);
  const [customBehaviorals, setCustomBehaviorals] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [linkPlatform, setLinkPlatform] = useState('');
  const [linkUsername, setLinkUsername] = useState('');
  const [linkPassword, setLinkPassword] = useState('');

  const mainContainerSx = {
    p: 2,
    backgroundColor: '#161d27',
    color: '#ccc',
    minHeight: '100vh'
  };
  const cardSx = {
    backgroundColor: '#161d27',
    border: '1px solid #444',
    color: '#ccc',
    mb: 2
  };
  const formControlSx = {
    mb: 2,
    mr: 2,
    minWidth: 200,
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': { borderColor: '#444', borderWidth: '1px' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#444' },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#444' }
    },
    '& .MuiFormLabel-root': { color: '#ccc' }
  };
  const textFieldSx = {
    mb: 2,
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': { borderColor: '#444', borderWidth: '1px' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#444' },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#444' }
    },
    '& .MuiFormLabel-root': { color: '#ccc' },
    '& .MuiInputBase-input': { color: '#ccc' }
  };

  useEffect(() => {
    setSelectedAgentId(null);
    setAgentProps(null);
    setSelectedSource('');
    setSelectedSecondary('');
    setActivePeriod('');
    setCurrentSecondaries([]);
    setErrorMsg('');
    setSuccessMsg('');
    setSocialLinks({
      twitter: '',
      facebook: '',
      instagram: '',
      tiktok: ''
    });
    setAddLater(false);
  }, []);

  useEffect(() => {
    if (modelList.length > 0) {
      const defaultAgent = modelList[0];
      setSelectedAgentId(defaultAgent.model_id);
      handleAgentChange(defaultAgent.model_id);
    }
  }, []);

  const handleAgentChange = async (newAgentId) => {
    setSelectedAgentId(newAgentId);
    const selectedModel = modelList.find(model => model.model_id === newAgentId);
    if (selectedModel) {
      setSelectedAvatar(`https://ui-avatars.com/api/?name=${encodeURIComponent(selectedModel.model_name)}`);
    }
    if (!newAgentId) {
      setAgentProps(null);
      return;
    }
    setLoadingAgent(true);
    const details = await getSelectedAgentDetails(newAgentId);
    setAgentProps(details);
    setLoadingAgent(false);
  };

  const handleSourceChange = async (value) => {
    setSelectedSource(value);
    setSelectedSecondary('');
    setCurrentSecondaries([]);
    if (!value) return;
    setLoadingSource(true);
    setErrorMsg('');
    setSuccessMsg('');
    try {
      const resp = await getUserSourceType(value, 55);
      const secondaries = (resp.data && Array.isArray(resp.data)) ? resp.data : [];
      setCurrentSecondaries(secondaries);
    } catch (err) {
      console.error('Error in handleSourceChange:', err);
      setErrorMsg('Error retrieving source data');
    } finally {
      setLoadingSource(false);
    }
  };

  const handleLinkClick = (platform) => {
    setLinkPlatform(platform);
    setDialogOpen(true);
  };

  const handleUnlink = (platform) => {
    setSocialLinks((prev) => {
      const newLinks = { ...prev };
      newLinks[platform] = '';
      return newLinks;
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setLinkPlatform('');
    setLinkUsername('');
    setLinkPassword('');
  };

  const handleLinkSubmit = () => {
    if (!linkUsername.trim() || !linkPassword.trim()) {
      return;
    }
    setSocialLinks((prev) => {
      const newLinks = { ...prev };
      newLinks[linkPlatform] = socialBaseUrls[linkPlatform] + linkUsername;
      return newLinks;
    });
    handleDialogClose();
  };

  const handleSave = async () => {
    setErrorMsg('');
    setSuccessMsg('');

    if (!selectedAgentId) {
      setErrorMsg('Agent selection is required.');
      return;
    }
    if (!addLater) {
      if (!selectedSource) {
        setErrorMsg('Source is required.');
        return;
      }
      if (!selectedSecondary) {
        setErrorMsg('Secondary source is required.');
        return;
      }
      if (!activePeriod) {
        setErrorMsg('Active period is required.');
        return;
      }
    }

    const configPayload = JSON.stringify({
      ...socialLinks,
      avatar: selectedAvatar,
      additionalDescriptions: customDescriptions,
      additionalBehaviorals: customBehaviorals
    });

    const canProceed = await checkIfPostConfigExistsAndSave(
      selectedAgentId,
      addLater ? '0' : selectedSource,
      addLater ? '0' : selectedSecondary,
      addLater ? '0' : activePeriod,
      configPayload
    );

    if (!canProceed) {
      setErrorMsg('This post configuration already exists!');
      setSuccessMsg('');
      return;
    }

    setErrorMsg('');
    setSuccessMsg('Saved successfully.');
    setSelectedAgentId(null);
    setAgentProps(null);
    setSelectedSource('');
    setSelectedSecondary('');
    setActivePeriod('');
    setCurrentSecondaries([]);
    setSocialLinks({
      twitter: '',
      facebook: '',
      instagram: '',
      tiktok: ''
    });
    setAddLater(false);
  };

  const handleCancel = () => {
    setErrorMsg('');
    setSuccessMsg('');
    setSelectedAgentId(null);
    setAgentProps(null);
    setSelectedSource('');
    setSelectedSecondary('');
    setActivePeriod('');
    setCurrentSecondaries([]);
    setSocialLinks({
      twitter: '',
      facebook: '',
      instagram: '',
      tiktok: ''
    });
    setAddLater(false);
  };

  const openEditDialog = () => {
    setSelectedDescriptions(customDescriptions);
    setAvailableDescriptions(descriptionOptions.filter(item => !customDescriptions.includes(item)));
    setSelectedBehaviorals(customBehaviorals);
    setAvailableBehaviorals(behavioralOptions.filter(item => !customBehaviorals.includes(item)));
    setEditDialogOpen(true);
  };

  // Functions for handling click-based transfers in the Description lists
  const moveDescriptionToSelected = (item) => {
    setSelectedDescriptions(prev => [...prev, item]);
    setAvailableDescriptions(prev => prev.filter(x => x !== item));
  };
  const moveDescriptionToAvailable = (item) => {
    setAvailableDescriptions(prev => [...prev, item]);
    setSelectedDescriptions(prev => prev.filter(x => x !== item));
  };

  // Functions for handling click-based transfers in the Behavioral lists
  const moveBehavioralToSelected = (item) => {
    setSelectedBehaviorals(prev => [...prev, item]);
    setAvailableBehaviorals(prev => prev.filter(x => x !== item));
  };
  const moveBehavioralToAvailable = (item) => {
    setAvailableBehaviorals(prev => [...prev, item]);
    setSelectedBehaviorals(prev => prev.filter(x => x !== item));
  };

  // Drag and drop handlers (for both lists)
  const handleDragStart = (e, item, type) => {
    e.dataTransfer.setData("text/plain", JSON.stringify({ item, type }));
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  const handleDropDescription = (e, targetList) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    if (data.type === "description") {
      if (targetList === "selected" && availableDescriptions.includes(data.item)) {
        moveDescriptionToSelected(data.item);
      } else if (targetList === "available" && selectedDescriptions.includes(data.item)) {
        moveDescriptionToAvailable(data.item);
      }
    }
  };

  const handleDropBehavioral = (e, targetList) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    if (data.type === "behavioral") {
      if (targetList === "selected" && availableBehaviorals.includes(data.item)) {
        moveBehavioralToSelected(data.item);
      } else if (targetList === "available" && selectedBehaviorals.includes(data.item)) {
        moveBehavioralToAvailable(data.item);
      }
    }
  };

  const handleEditSave = () => {
    // update custom values based on the user’s selection in the dialog
    setCustomDescriptions(selectedDescriptions);
    setCustomBehaviorals(selectedBehaviorals);
    setEditDialogOpen(false);
  };

  const handleEditCancel = () => {
    setEditDialogOpen(false);
  };

  return (
    <Box sx={mainContainerSx}>
      {/* Dialog for linking social account */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            backgroundColor: '#161d27',
            border: '1px solid #444',
            color: '#ccc'
          }
        }}
      >
        <DialogTitle sx={{ color: '#ccc' }}>Link {linkPlatform} Account</DialogTitle>
        <DialogContent>
          <TextField
            label="Username"
            variant="outlined"
            sx={textFieldSx}
            value={linkUsername}
            onChange={(e) => setLinkUsername(e.target.value)}
            fullWidth
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            sx={textFieldSx}
            value={linkPassword}
            onChange={(e) => setLinkPassword(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleDialogClose}
            startIcon={<CancelIcon sx={{ color: '#ccc' }} />}
            sx={{ textTransform: 'none', borderColor: '#444', color: '#ccc' }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleLinkSubmit}
            startIcon={<LinkIcon sx={{ color: '#ccc' }} />}
            sx={{ textTransform: 'none', borderColor: '#444', color: '#ccc' }}
          >
            Link
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for editing additional Description and Behavioral attributes */}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditCancel}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: '#161d27',
            border: '1px solid #444',
            color: '#ccc'
          }
        }}
      >
        <DialogTitle sx={{ color: '#ccc' }}>Edit Additional Attributes</DialogTitle>
        <DialogContent>
          {/* Transfer List for Descriptions */}
          <Typography variant="h6" sx={{ mt: 2, mb: 1, color: '#ccc' }}>Edit Descriptions</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ color: '#ccc' }}>Available</Typography>
              <Box
                sx={{ border: '1px solid #444', minHeight: 150, p: 1 }}
                onDragOver={allowDrop}
                onDrop={(e) => handleDropDescription(e, "selected")}
              >
                {availableDescriptions.map((item, index) => (
                  <Box
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, item, "description")}
                    onClick={() => moveDescriptionToSelected(item)}
                    sx={{ p: 1, border: '1px solid #444', mb: 1, cursor: 'pointer', color: '#ccc' }}
                  >
                    {item}
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ color: '#ccc' }}>Selected</Typography>
              <Box
                sx={{ border: '1px solid #444', minHeight: 150, p: 1 }}
                onDragOver={allowDrop}
                onDrop={(e) => handleDropDescription(e, "available")}
              >
                {selectedDescriptions.map((item, index) => (
                  <Box
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, item, "description")}
                    onClick={() => moveDescriptionToAvailable(item)}
                    sx={{ p: 1, border: '1px solid #444', mb: 1, cursor: 'pointer', color: '#ccc' }}
                  >
                    {item}
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>

          {/* Transfer List for Behavioral */}
          <Typography variant="h6" sx={{ mt: 4, mb: 1, color: '#ccc' }}>Edit Behavioral</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ color: '#ccc' }}>Available</Typography>
              <Box
                sx={{ border: '1px solid #444', minHeight: 150, p: 1 }}
                onDragOver={allowDrop}
                onDrop={(e) => handleDropBehavioral(e, "selected")}
              >
                {availableBehaviorals.map((item, index) => (
                  <Box
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, item, "behavioral")}
                    onClick={() => moveBehavioralToSelected(item)}
                    sx={{ p: 1, border: '1px solid #444', mb: 1, cursor: 'pointer', color: '#ccc' }}
                  >
                    {item}
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ color: '#ccc' }}>Selected</Typography>
              <Box
                sx={{ border: '1px solid #444', minHeight: 150, p: 1 }}
                onDragOver={allowDrop}
                onDrop={(e) => handleDropBehavioral(e, "available")}
              >
                {selectedBehaviorals.map((item, index) => (
                  <Box
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, item, "behavioral")}
                    onClick={() => moveBehavioralToAvailable(item)}
                    sx={{ p: 1, border: '1px solid #444', mb: 1, cursor: 'pointer', color: '#ccc' }}
                  >
                    {item}
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleEditCancel}
            startIcon={<CancelIcon sx={{ color: '#ccc' }} />}
            sx={{ textTransform: 'none', borderColor: '#444', color: '#ccc' }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleEditSave}
            startIcon={<CheckCircleIcon sx={{ color: '#ccc' }} />}
            sx={{ textTransform: 'none', borderColor: '#444', color: '#ccc' }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Social Accounts */}
      <Box sx={cardSx}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2, color: '#ccc' }}>Social Accounts:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, overflowX: 'auto' }}>
            {['twitter', 'facebook', 'instagram', 'tiktok'].map((platform) => {
              const isLinked = Boolean(socialLinks[platform]);
              return (
                <Box
                  key={platform}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 1,
                    p: 1,
                    minWidth: 150
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {platform === 'twitter' && <TwitterIcon sx={{ color: '#1DA1F2' }} />}
                    {platform === 'facebook' && <FacebookIcon sx={{ color: '#1877F2' }} />}
                    {platform === 'instagram' && <InstagramIcon sx={{ color: '#E1306C' }} />}
                    {platform === 'tiktok' && <MusicNoteIcon sx={{ color: 'magenta' }} />}
                  </Box>
                  <Typography variant="body2" sx={{ mt: 1, color: isLinked ? 'lime' : 'orange' }}>
                    {isLinked ? 'Linked' : 'Not Linked'}
                  </Typography>
                  {isLinked ? (
                    <Button
                      variant="outlined"
                      sx={{ mt: 1, textTransform: 'none', borderColor: '#444', color: '#ccc' }}
                      onClick={() => handleUnlink(platform)}
                    >
                      Unlink
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{ mt: 1, textTransform: 'none', borderColor: '#444', color: '#ccc' }}
                      onClick={() => handleLinkClick(platform)}
                    >
                      Link Account
                    </Button>
                  )}
                </Box>
              );
            })}
          </Box>
        </CardContent>
      </Box>
      <Box sx={cardSx}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2, color: '#ccc' }}>Select character:</Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
            {/* Left column: character select and avatar directly under it */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Avatar
                  src={selectedAvatar}
                  alt="Selected Character Avatar"
                  sx={{ width: 80, height: 80 }}
                />
              </Box>
              <FormControl sx={formControlSx}>
                <InputLabel sx={{ color: '#ccc' }}>Character</InputLabel>
                <Select
                  sx={{ color: '#ccc' }}
                  value={selectedAgentId || ''}
                  onChange={(e) => handleAgentChange(Number(e.target.value))}
                  label="Character"
                >
                  <MenuItem value="">Choose a Character</MenuItem>
                  {modelList.map((agent) => (
                    <MenuItem key={agent.model_id} value={agent.model_id}>
                      {agent.model_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* Right column: character details */}
            <Box sx={{ flex: 1, p: 2, border: '1px solid #444', borderRadius: 1, position: 'relative' }}>
              {loadingAgent ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} sx={{ color: '#ccc' }} />
                  <Typography sx={{ color: '#ccc' }}>Loading character details...</Typography>
                </Box>
              ) : agentProps ? (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2" sx={{ color: '#ccc' }}>
                      Trait: <span style={{ color: '#fff' }}>{agentProps.trait}</span>
                    </Typography>
                    {/* Edit icon in the top right corner */}
                    <IconButton onClick={openEditDialog} sx={{ color: '#ccc' }}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="subtitle2" sx={{ color: '#ccc' }}>
                    Description: <span style={{ color: '#fff' }}>{agentProps.description}</span>
                  </Typography>
                  {customDescriptions.length > 0 && (
                    <Typography variant="subtitle2" sx={{ color: '#ccc' }}>
                      Additional Descriptions: <span style={{ color: '#fff' }}>{customDescriptions.join(', ')}</span>
                    </Typography>
                  )}
                  <Typography variant="subtitle2" sx={{ color: '#ccc' }}>
                    Behavioral: <span style={{ color: '#fff' }}>{agentProps.behavioral_manifestation}</span>
                  </Typography>
                  {customBehaviorals.length > 0 && (
                    <Typography variant="subtitle2" sx={{ color: '#ccc' }}>
                      Additional Behavioral: <span style={{ color: '#fff' }}>{customBehaviorals.join(', ')}</span>
                    </Typography>
                  )}
                </>
              ) : (
                <Typography variant="body2" sx={{ color: '#999' }}>No agent selected</Typography>
              )}
            </Box>
          </Box>
        </CardContent>
      </Box>

      <Box sx={cardSx}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6" sx={{ color: '#ccc' }}>Configure Post Settings:</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addLater}
                  onChange={(e) => {
                    setAddLater(e.target.checked);
                    if (e.target.checked) {
                      setSelectedSource('0');
                      setSelectedSecondary('0');
                      setActivePeriod('0');
                    } else {
                      setSelectedSource('');
                      setSelectedSecondary('');
                      setActivePeriod('');
                    }
                  }}
                  sx={{ color: '#ccc' }}
                />
              }
              label="Add later"
              sx={{ color: '#ccc' }}
            />
          </Box>
          {errorMsg && (
            <Alert severity="error" sx={{ mb: 2, backgroundColor: '#261d1d', color: 'red' }}>
              {errorMsg}
            </Alert>
          )}
          {successMsg && (
            <Alert severity="success" sx={{ mb: 2, backgroundColor: '#1d2620', color: 'green' }}>
              {successMsg}
            </Alert>
          )}
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={formControlSx}>
              <InputLabel sx={{ color: '#ccc' }}>Source</InputLabel>
              <Select
                sx={{ color: '#ccc' }}
                value={selectedSource}
                onChange={(e) => handleSourceChange(e.target.value)}
                label="Source"
                disabled={addLater}
              >
                <MenuItem value="">Select a source</MenuItem>
                <MenuItem value="YouTube">YouTube</MenuItem>
                <MenuItem value="Url">Url</MenuItem>
                <MenuItem value="Twitter">Twitter</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={formControlSx} disabled={addLater || loadingSource || !selectedSource}>
              <InputLabel sx={{ color: '#ccc' }}>Secondary Source</InputLabel>
              <Select
                sx={{ color: '#ccc' }}
                value={selectedSecondary}
                onChange={(e) => setSelectedSecondary(e.target.value)}
                label="Secondary Source"
              >
                <MenuItem value="">Select secondary</MenuItem>
                {currentSecondaries.map((sec, index) => (
                  <MenuItem key={index} value={sec.id}>
                    {sec.transcript_title || JSON.stringify(sec)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={formControlSx}>
              <InputLabel sx={{ color: '#ccc' }}>Active Period</InputLabel>
              <Select
                sx={{ color: '#ccc' }}
                value={activePeriod}
                onChange={(e) => setActivePeriod(e.target.value)}
                label="Active Period"
                disabled={addLater}
              >
                <MenuItem value="">Select period</MenuItem>
                <MenuItem value="morning">Morning</MenuItem>
                <MenuItem value="afternoon">Afternoon</MenuItem>
                <MenuItem value="evening">Evening</MenuItem>
                <MenuItem value="night">Night</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {loadingSource && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
              <CircularProgress size={20} sx={{ color: '#ccc' }} />
              <Typography sx={{ color: '#ccc' }}>Retrieving source data...</Typography>
            </Box>
          )}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="outlined"
              sx={{ textTransform: 'none', borderColor: '#444', color: '#ccc' }}
              startIcon={<CancelIcon sx={{ color: '#ccc' }} />}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: 'none', borderColor: '#444', color: '#ccc' }}
              startIcon={<CheckCircleIcon sx={{ color: '#ccc' }} />}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </CardContent>
      </Box>
    </Box>
  );
};

export default SocialAccounts;
