import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../core/api/auth';

const Navbar = ({ toggleSidebar }) => {
  const [notifications] = useState([
    {
      category: 'Analytics',
      message: "Your website’s active users count increased by <span class='text-success text-semibold'>28%</span> in the last week. Great job!",
      icon: "&#xE6E1;",
    },
    {
      category: 'Sales',
      message: "Last week your store’s sales count decreased by <span class='text-danger text-semibold'>5.52%</span>. It could have been worse!",
      icon: "&#xE8D1;",
    },
  ]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="main-navbar sticky-top">
      <nav className="navbar align-items-center navbar-light flex-md-nowrap p-0">
        <form action="#" className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
        </form>

        <div className="d-flex align-items-center ml-auto">
          <div className="nav-item dropdown notifications" ref={dropdownRef}>
            <Link
              className="nav-link nav-link-icon text-center"
              to="#"
              role="button"
              onClick={toggleDropdown}
              aria-haspopup="true"
              aria-expanded={dropdownOpen ? 'true' : 'false'}
            >
              <div className="nav-link-icon__wrapper">
                <i className="material-icons">&#xE7F4;</i>
                <span className="badge badge-pill badge-danger">{notifications.length}</span>
              </div>
            </Link>

            {dropdownOpen && (
              <div className="dropdown-menu dropdown-menu-small show">
                {notifications.map((notification, index) => (
                  <Link className="dropdown-item" to="#" key={index}>
                    <div className="notification__icon-wrapper">
                      <div className="notification__icon">
                        <i className="material-icons" dangerouslySetInnerHTML={{ __html: notification.icon }} />
                      </div>
                    </div>
                    <div className="notification__content">
                      <span className="notification__category">{notification.category}</span>
                      <p dangerouslySetInnerHTML={{ __html: notification.message }} />
                    </div>
                  </Link>
                ))}
                <Link className="dropdown-item notification__all text-center" to="#">
                  View all Notifications
                </Link>
              </div>
            )}
          </div>

          <div className="nav-item">
            <button className="nav-link nav-link-icon btn btn-link text-center" onClick={() => logout()}>
              <i className="material-icons">exit_to_app</i>
            </button>
          </div>

          <nav className="nav Pg_Togller">
            <Link
              to="#"
              className="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center"
              role="button"
              onClick={toggleSidebar}
            >
              <i className="material-icons">&#xE5D2;</i>
            </Link>
          </nav>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;