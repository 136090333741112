import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import GifIcon from '@mui/icons-material/Gif';
import AnimationIcon from '@mui/icons-material/Animation';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

// For the single "master" chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// API calls
import {
  loadSavedAgent,
  getAgentPosts,
  getAgentShopProducts,
} from '../../../core/api/auth';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// ----------------------------------------
// Page-level layout styles
// ----------------------------------------

// The outer container for the entire page
const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
  backgroundColor: '#161d27',
  color: '#ccc',
  overflow: 'hidden', // Hide any stray overflow
};

// The container that holds the frames (Agent Chart + Playground)
const framesContainerSx = {
  flex: 1,              // Takes up all remaining space under the top bar
  display: 'flex',
  flexDirection: 'column', // We'll stack frames top-to-bottom
  overflow: 'hidden',   // No horizontal or vertical overflow
};

// Each frame
// We use "flex: 1" so that if both frames are rendered, each will share equally.
// If only one frame is rendered, it alone takes 100% of the parent's height.
const frameSx = {
  flex: 1,
  overflowY: 'auto', // Allow vertical scrolling within the frame
  p: 2,
  boxSizing: 'border-box',
};

// Shared card styles
const cardSx = {
  backgroundColor: '#161d27',
  border: '1px solid #444',
  color: '#ccc',
  mb: 2,
};

const selectSx = {
  color: '#ccc',
  '& .MuiOutlinedInput-notchedOutline': { borderColor: '#444' },
};

const toggleLabelSx = { mr: 3, color: '#ccc' };

const HomePage = () => {
  // Agents loaded from the API
  const [agents, setAgents] = useState([]);

  // Agent selected in dropdown
  const [selectedAgentActions, setSelectedAgentActions] = useState('');

  // Toggles: all off by default
  const [toggles, setToggles] = useState({
    summaries: false,
    socialMedia: false,
    shops: false,
    products: false,
    sells: false,
  });

  // Data used by the chart
  const [chartData, setChartData] = useState({
    summaries: [],
    socialMedia: [],
    shops: [],
    products: [],
    sells: [],
  });

  // Playground states
  const [videoLink, setVideoLink] = useState('');
  const [activePlaygroundMenu, setActivePlaygroundMenu] = useState(''); // "gif" or "anime"
  const [gifTime, setGifTime] = useState('');

  // Show/hide frames
  const [showAgentChart, setShowAgentChart] = useState(false);
  const [showPlayground, setShowPlayground] = useState(true); // Playground shown by default

  // Fetch Agents on mount
  useEffect(() => {
    loadSavedAgent(55)
      .then((data) => {
        setAgents(data);
      })
      .catch((err) => console.error(err));
  }, []);

  // Load chart data for a given agent
  const loadChartDataForAgent = async (agentId) => {
    try {
      const posts = await getAgentPosts(agentId);
      const postsLength = posts.length;

      const products = await getAgentShopProducts(agentId);
      const productsLength = products.length;

      // We'll generate random "growth" arrays (7 time points) for each category.
      const randomCount = () => Math.floor(Math.random() * 40) + 5;

      // Helper to build a 7-point ascending array
      const buildGrowthArray = (finalCount) => {
        const arr = [];
        let current = 0;
        for (let i = 0; i < 7; i++) {
          current += Math.floor(finalCount / 7) + Math.floor(Math.random() * 3);
          if (current > finalCount) current = finalCount;
          arr.push(current);
        }
        return arr;
      };

      const newChartData = {
        summaries: buildGrowthArray(randomCount()),
        socialMedia: buildGrowthArray(postsLength),
        shops: buildGrowthArray(randomCount()),
        products: buildGrowthArray(productsLength),
        sells: buildGrowthArray(randomCount()),
      };

      setChartData(newChartData);
    } catch (err) {
      console.error(err);
    }
  };

  // When user selects an agent from dropdown
  const handleAgentActionsChange = async (event) => {
    const agentId = event.target.value;
    setSelectedAgentActions(agentId);
    await loadChartDataForAgent(agentId);
  };

  // Toggles
  const handleToggleChange = (toggleName, isChecked) => {
    setToggles((prev) => ({
      ...prev,
      [toggleName]: isChecked,
    }));
  };

  // Chart data
  const labels = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];

  const colorsMap = {
    summaries: 'rgb(255, 99, 132)',
    socialMedia: 'rgb(54, 162, 235)',
    shops: 'rgb(255, 206, 86)',
    products: 'rgb(75, 192, 192)',
    sells: 'rgb(153, 102, 255)',
  };

  const activeDatasets = Object.keys(toggles)
    .filter((key) => toggles[key])
    .map((key) => ({
      label: key,
      data: chartData[key],
      borderColor: colorsMap[key],
      backgroundColor: `${colorsMap[key]}33`, // some transparency
    }));

  const masterChartData = {
    labels,
    datasets: activeDatasets,
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: '#ccc',
        },
      },
      title: {
        display: true,
        text: 'Growth Chart',
        color: '#ccc',
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ccc',
        },
        grid: {
          color: '#444',
        },
      },
      y: {
        ticks: {
          color: '#ccc',
        },
        grid: {
          color: '#444',
        },
      },
    },
  };

  // Playground
  const handlePlaygroundMenuClick = (menuItem) => {
    setActivePlaygroundMenu(menuItem);
    setGifTime('');
  };

  const handleMakeGif = () => {
    if (!videoLink.trim()) {
      alert('Please provide a video link first!');
      return;
    }
    if (!gifTime.trim()) {
      alert('Please provide the time in the video to create GIF from!');
      return;
    }
    alert(`Creating GIF from ${videoLink}, at time: ${gifTime} seconds...`);
  };

  const handleMakeAnime = () => {
    if (!videoLink.trim()) {
      alert('Please provide a video link first!');
      return;
    }
    alert(`Processing anime from ${videoLink}...`);
  };

  const handleCancel = () => {
    setGifTime('');
    setActivePlaygroundMenu('');
  };

  // Agent Chart Button
  const handleAgentChartClick = async () => {
    setShowAgentChart((prev) => !prev);

    // If we are toggling from OFF -> ON:
    if (!showAgentChart) {
      if (agents && agents.length > 0) {
        const firstAgentId = agents[0].id;
        setSelectedAgentActions(firstAgentId);

        // Only the first toggle, e.g. "summaries", is turned on
        setToggles({
          summaries: true,
          socialMedia: false,
          shops: false,
          products: false,
          sells: false,
        });

        await loadChartDataForAgent(firstAgentId);
      }
    }
  };

  // Playground Button
  const handlePlaygroundClick = () => {
    setShowPlayground((prev) => !prev);
  };

  const agentBtnLabel = agents.length > 0 ? 'Add New Agent' : 'Create an agent';

  // Determine icon button colors
  // If the frame is active, color = #FF9800, else #ccc
  const playgroundIconColor = showPlayground ? '#FF9800' : '#ccc';
  const agentChartIconColor = showAgentChart ? '#FF9800' : '#ccc';

  return (
    <Box sx={containerSx}>
      {/* Top bar with "Dashboard" text and two icon buttons */}
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {/* Left side: Title */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                textTransform: 'uppercase',
                color: '#ccc',
                mr: 2,
              }}
            >
              Dashboard:
            </Typography>

            {/* Icon Button: Playground */}
            Play Ground
            <IconButton
              onClick={handlePlaygroundClick}
              sx={{ color: playgroundIconColor, mr: 1 }}
            >
            <VideogameAssetIcon />
            </IconButton>

            {/* Icon Button: Agent Chart */}
            Agent Activities
            <IconButton
              onClick={handleAgentChartClick}
              sx={{ color: agentChartIconColor }}
            >
              <BarChartIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Container for frames */}
      <Box sx={framesContainerSx}>
        {/* Agent Chart Frame */}
        {showAgentChart && (
          <Box sx={frameSx}>
            <Card sx={cardSx}>
              <CardHeader
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    {/* Agent Selection */}
                    <FormControl size="small" variant="outlined" sx={{ minWidth: 160 }}>
                      <InputLabel sx={{ color: '#ccc' }}>Select Agent</InputLabel>
                      <Select
                        value={selectedAgentActions}
                        onChange={handleAgentActionsChange}
                        label="Select Agent"
                        sx={selectSx}
                      >
                        {agents.map((agent) => (
                          <MenuItem key={agent.id} value={agent.id}>
                            {agent.model_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Add New Agent / Create Agent button */}
                    <IconButton
                      sx={{ ml: 2, color: '#ccc' }}
                      onClick={() => alert('Add New Agent logic goes here')}
                    >
                      <AddCircleIcon />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {agentBtnLabel}
                      </Typography>
                    </IconButton>
                  </Box>
                }
                sx={{ borderBottom: '1px solid #444' }}
              />
              <CardContent>
                {/* Toggles & Details in a 2-column Grid (mobile-friendly) */}
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  {/* Left side: Toggles */}
                  <Grid item xs={12} md={7}>
                    <FormGroup row>
                      {Object.keys(toggles).map((toggleKey) => (
                        <FormControlLabel
                          key={toggleKey}
                          control={
                            <Switch
                              checked={toggles[toggleKey]}
                              onChange={(e) =>
                                handleToggleChange(toggleKey, e.target.checked)
                              }
                            />
                          }
                          label={toggleKey}
                          sx={toggleLabelSx}
                        />
                      ))}
                    </FormGroup>
                  </Grid>

                  {/* Right side: "Details" about toggles */}
                  <Grid item xs={12} md={5}>
                    <Box
                      sx={{
                        border: '1px solid #444',
                        p: 1,
                        borderRadius: 1,
                        backgroundColor: '#1f2732',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ textTransform: 'uppercase', mb: 1, color: '#FF9800' }}
                      >
                        Toggle Details:
                      </Typography>
                      {Object.keys(toggles).some((key) => toggles[key]) ? (
                        Object.keys(toggles)
                          .filter((key) => toggles[key])
                          .map((toggleKey) => {
                            const dataArray = chartData[toggleKey];
                            const finalValue =
                              dataArray && dataArray.length > 0
                                ? dataArray[dataArray.length - 1]
                                : 0;
                            return (
                              <Typography
                                key={toggleKey}
                                variant="body2"
                                sx={{ mb: 0.5 }}
                              >
                                {toggleKey} is selected – final Day 7 value: {finalValue}
                              </Typography>
                            );
                          })
                      ) : (
                        <Typography variant="body2">No toggles selected.</Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                {/* Master Chart: only show after an agent is selected */}
                <Box sx={{ width: '100%', height: '40vh' }}>
                  {selectedAgentActions ? (
                    activeDatasets.length === 0 ? (
                      <Typography variant="body2">
                        No data selected. Toggle any of the categories above.
                      </Typography>
                    ) : (
                      <Line data={masterChartData} options={chartOptions} />
                    )
                  ) : (
                    <Typography variant="body2">
                      Please select an agent to load chart data.
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
        )}

        {/* Playground Frame */}
        {showPlayground && (
          <Box sx={frameSx}>
            <Card sx={cardSx}>
              <CardHeader
                title={
                  <Typography variant="h6" sx={{ color: '#ccc' }}>
                    Play Ground:
                  </Typography>
                }
                sx={{ borderBottom: '1px solid #444' }}
              />
              <CardContent>
                {/* Video Link Text Field */}
                <TextField
                  label="Video Link"
                  variant="outlined"
                  fullWidth
                  value={videoLink}
                  onChange={(e) => setVideoLink(e.target.value)}
                  sx={{
                    mb: 2,
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#444' },
                    '& .MuiFormLabel-root': { color: '#ccc' },
                    '& .MuiInputBase-input': { color: '#fff' },
                  }}
                />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <Card
                      sx={{
                        backgroundColor: '#161d27',
                        border: '1px solid #444',
                        color: '#ccc',
                      }}
                    >
                      <List disablePadding>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handlePlaygroundMenuClick('gif')}
                            selected={activePlaygroundMenu === 'gif'}
                          >
                            <GifIcon sx={{ mr: 1 }} />
                            <ListItemText primary="Make GIF from video" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handlePlaygroundMenuClick('anime')}
                            selected={activePlaygroundMenu === 'anime'}
                          >
                            <AnimationIcon sx={{ mr: 1 }} />
                            <ListItemText primary="Make Anime" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={9}>
                    {/* "Make GIF from video" menu */}
                    {activePlaygroundMenu === 'gif' && (
                      <Box sx={{ mb: 2 }}>
                        <TextField
                          label="Time in video (seconds)"
                          variant="outlined"
                          value={gifTime}
                          onChange={(e) => setGifTime(e.target.value)}
                          sx={{
                            mr: 2,
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#444',
                            },
                            '& .MuiFormLabel-root': { color: '#ccc' },
                            '& .MuiInputBase-input': { color: '#fff' },
                          }}
                        />
                        <IconButton onClick={handleMakeGif} sx={{ color: '#ccc' }}>
                          <CheckIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel} sx={{ color: '#ccc' }}>
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    )}

                    {/* "Make Anime" menu */}
                    {activePlaygroundMenu === 'anime' && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          Click "Make" to process anime from the above link.
                        </Typography>
                        <IconButton
                          onClick={handleMakeAnime}
                          sx={{ color: '#ccc', mr: 1 }}
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel} sx={{ color: '#ccc' }}>
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HomePage;
